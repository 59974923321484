<template>
    <div   >
        <div style="margin-top:1px; ">
            <div style="display:flex;justify-content:space-between;background:#E4E7ED;line-height:40px;height:40px;border-left:2px solid #409EFF;">
                <span style="margin-left:10px;color:#303133; font-size:14px;">{{fldObj['x_order']}} > {{fldObj['x_ask']}}</span>
                <div  style=" margin-right:8px  "><el-link :underline="false" @click="addReqNo">
                    <i class="iconfont icon-xinzeng" style="margin-right:6px;color:#409eff;"></i>{{fldObj['x_create']}}</el-link>
                </div>
            </div>
        </div>
 
        <el-card  style="margin-bottom:15px;">
            <div style="display:flex;align-items: center;">
                <div style="width:80px;text-align:right;margin-right:5px; ">{{fldObj['x_askno']}}</div>
                <el-input :placeholder="fldObj['x_askno']" v-model="condition.idno" size="medium" style="width:160px;"></el-input>
                <span style="width:90px;text-align:right;margin-right:5px;">{{fldObj['x_model']}}</span>
                <el-input :placeholder="fldObj['x_model']" v-model="condition.matno" size="medium" style="width:160px;"></el-input>
                <span style="width:80px;text-align:right;margin-right:5px;">{{fldObj['x_brand']}}</span>
                <el-input :placeholder="fldObj['x_brand']" v-model="condition.facno" size="medium" style="width:150px;"></el-input>
                <span style="width:80px;text-align:right;margin-right:5px;">{{fldObj['x_status']}}</span>
                <el-select   v-model="condition.stfg" size="medium" style="width:160px;">
                    <el-option value="all"  :label="fldObj['x_all']"> </el-option>
                    <el-option value="wait" :label="fldObj['x_inquote']"></el-option> 
                    <el-option value="ok" :label="fldObj['x_quote']"></el-option>
                    <el-option value="cancel" :label="fldObj['x_cancel']"></el-option> 
                </el-select>  
            </div>  
            <div style="display:flex;align-items: center;margin-top:10px;">
                <div style="width:80px;text-align:right;margin-right:3px; ">{{fldObj['x_from']}}</div>
                <el-date-picker
                    v-model="condition.dttm"
                    align="right"
                    type="date"
                    value-format="yyyy-MM-dd"
                    size="medium"
                    style="width:160px; "
                    placeholder="Please select"
                    :picker-options="pickerOptions">
                </el-date-picker>
                <span style=" width:90px;text-align:right;margin-right:3px;">{{fldObj['x_to']}}</span>
                <el-date-picker
                    v-model="condition.todttm"
                    align="right"
                    type="date"
                    value-format="yyyy-MM-dd"
                    size="medium"
                    style="width:160px; "
                    placeholder="Please select"
                    :picker-options="pickerOptions">
                </el-date-picker>
                <el-button @click="btnClear" size="small" plain class="btncss" icon="el-icon-close" style="margin-right:20px;">{{fldObj['x_reset']}}</el-button>
                <el-button @click="btnQry" size="small" class="btncss2" icon="el-icon-search" >{{fldObj['x_query']}}</el-button>
            </div>
            
        </el-card>
        <div style="margin-top:20px;background:#E4E7ED;line-height:40px;height:40px;border-left:2px solid #409EFF;display:flex">
            <div style="margin-left:10px;color:#F56C6C; font-size:14px;text-align:left;">
                {{fldObj['x_warm']}}
            </div>
        </div>
        <div style="margin-top:10px; ">
            <!-- <div style="background:#E4E7ED;line-height:40px;height:40px;border :1px solid #ccc;display:flex">
                <span style="margin-left:10px;color:#303133; font-size:14px;">数据列表</span>
                <div style="margin-left:30px;color:#303133; font-size:14px;margin-right:6px;">每页
                    <el-select @change="initPage"  v-model="rowSize" size="small" style="width:80px;margin-right:30px;">
                        <el-option 
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <el-select   v-model="ordstfg" size="small" style="width:180px;margin-right:30px;">
                    <el-option value="all" label="所有订单"> </el-option>
                    <el-option value="month" label="最近3个月的订单"> </el-option>
                    <el-option value="year" label="最近一年的订单"> </el-option>
                    <el-option value="past" label="一年以前的订单"> </el-option>
                </el-select>
            </div> -->
 
            <div v-for="(item,index) in ordArr" :key="index" style="border-bottom:1px solid #ccc;" :class="index==0?'top1':'top2'"> 
                <div class="divhover"  >
                    <div style="display:flex ;line-height:35px;border-top:1px solid #ccc;border-left:1px solid #ccc;border-right:1px solid #ccc">
                        <div style="width:100px;text-align:left;padding-left:5px;background:#DCDCDC">{{fldObj['x_askno']}}</div>
                        <div style="width:180px;text-align:left;padding-left:9px">{{item.IDNO}}</div>
                        <div style="width:100px;text-align:left;padding-left:5px;background:#DCDCDC">{{fldObj['x_askdate']}}</div>
                        <div style="width:178px;text-align:left;padding-left:9px">{{item.ADDDTTM}}</div>
                        <div style="width:100px;text-align:left;padding-left:5px;background:#DCDCDC">{{fldObj['x_user']}}</div>
                        <div style="width:180px;text-align:left;padding-left:9px">{{item.EMPNM}}</div>
                        <div style="width:100px;text-align:left;padding-left:5px;background:#DCDCDC">{{fldObj['x_status']}}</div>
                        <div style="width:180px;text-align:left;padding-left:9px">{{v_lang=='CN'?item.STFGNM:item.ESTFGNM}}</div>
                    </div>
 
                    <div style="display:flex ;line-height:35px;border-top:1px solid #ccc;border-left:1px solid #ccc;border-right:1px solid #ccc">
                        <div style="width:600px; padding-left:5px;background: #ccc;">{{fldObj['x_content']}}</div>
                        <div style="width:600px;padding-left:5px;background: #ccc; ">{{fldObj['x_reply']}}</div>
                    </div>
                    <div style="min-height:40px; display:flex;" v-for="(subitem,subindex) in item.children" :key="subindex">
                        <div  style="width:597px;border-left:1px solid #ccc;word-wrap:break-word"  class="divcol2" >
                            <span   style=" font-size:12px; " >{{fldObj['x_type']}}：{{v_lang=='CN'?subitem.MATTYNM:subitem.EMATTYNM}}</span>
                            <span   style=" font-size:12px;margin-left:5px" >{{fldObj['x_prdname']}}：{{subitem.MATNM}}</span>
                            <span   style=" font-size:12px;margin-left:5px" >{{fldObj['x_qty']}} (PCS)：{{subitem.QTY}}</span>
                            <span   style=" font-size:12px;margin-left:5px" >{{fldObj['x_prc']}}：{{subitem.PRC}}[{{subitem.CRCYNM}}]</span>
                            <span   style=" font-size:12px;margin-left:5px" >{{fldObj['x_valid']}}：{{v_lang=='CN'?subitem.SHPDAY:subitem.ESHPDAY}}</span>
                            <span v-if="subitem.ISATT=='Y'"  style=" font-size:12px;margin-left:5px;line-height:30px" >
                               <el-link @click="imgPdf(subitem.IDSEQ,subitem.LSTSEQ)" :underline="false" style="color:#409eff;line-height:30px"> {{fldObj['x_att']}}<icon class="iconfont icon-chaolianjie1" style="margin-left:5px;"></icon> </el-link>
                            </span>
                        </div>

                        <div  style="width:600px;word-wrap:break-word; border-left:1px solid #ccc;"  class="divcol"  >
                            <span   style=" font-size:12px; " >{{fldObj['x_with']}} PN：
                                <el-link :underline="false" style="color:#409eff" @click="prdList(subitem.REFIDSEQ,subitem.REFMATNO)">{{subitem.REFMATNO}}</el-link>
                            </span>
                            <span   style=" font-size:12px;margin-left:5px" >{{fldObj['x_saleprc']}}：{{subitem.REPLYPRC}}</span>
                            <span   style=" font-size:12px;margin-left:5px" >{{fldObj['x_salereply']}}：{{subitem.FEEDRMK}}</span>
                        </div>
                    </div>  
                </div> 
            </div>
            <!-- 分页查询 -->
            <div style="display:flex;justify-content:center;line-height:30px;align-items:center" class="notop">              
                <Page :total="dataCount" :page-size="rowSize" show-total :current="current" @on-change="changePage"></Page>
                <span style="color:#606266;margin:7px 10px;font-size:12px;">{{fldObj['x_page']}}</span>
                <el-select v-model="rowSize" size="small" style="width:70px;" @change="initPage">
                        <el-option 
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                </el-select>
                <span style="color:#606266;margin:7px 10px;font-size:12px;">{{fldObj['x_goto']}}</span>
                <el-input v-enter-number size="small" v-model="goPage" style="width:50px;"></el-input> 
                <span style="color:#606266;margin:7px 5px;font-size:12px;">{{fldObj['x_page2']}}</span>
                <el-button size="small" @click="goNext" type="primary" style="width:50px;margin-left:15px;height:30px;">GO</el-button>
            </div>
        </div>
        <el-dialog  :visible.sync="imgWin" width="750px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">Attachment</span>
            </div>
            <div style="display:flex" >   
                <div v-for="(item,index) in imgArr" :key="index" style="display:flex;flex-direction:column;height:150px;margin-left:10px;"  >
                    <span v-if="item.FILEPATH.indexOf('pdf')>-1 ||item.FILEPATH.indexOf('PDF')>-1">
                        <div style="width:200px;height:126px;border:1px solid #e8eaec;overflow-y:hidden">
                            <iframe :src="item.FILEPATH" frameborder="0" style="width: 100%; height: 100%"></iframe>
                        </div>
                        <div style="height:25px;background:#e8eaec;width:200px; display:flex;justify-content:center">
                            <el-link :underline="false" style="margin-right:100px;" @click="pdfViewer(item.FILEPATH)"><Icon type="ios-search" />View</el-link>
                        </div>
                    </span>
                    <span v-else>
                        <el-image fit="scale-down"  style="width:120px;height:120px;margin-left:15px;" :src="item.FILEPATH"></el-image>
                        <div style="height:25px;background:#e8eaec;width:150px; display:flex;justify-content:space-between">
                            <el-link :underline="false" @click="imgPreview(item.FILEPATH)"><Icon type="ios-search" /> Preview</el-link>
                        </div>
                    </span>
                </div> 
            </div>
        </el-dialog>
        <el-dialog :visible.sync="showViewer" width="70vh" append-to-body :close-on-click-modal="false" :close-on-press-escape="false">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">Preview</span>
            </div>
            <el-image fit="scale-down"  style=" margin:5px auto;height:500px;width:100% " :src="listurl"></el-image>
        </el-dialog>
    </div>
</template>
<script>
import {getBaseData,getTabColOrData} from '../../api/user';  
//import askPrc from '../ordmgt/prd_AskPrc'
export default {
    props:{
        v_lang :{type: String }
    },
    data () {
        return {
            payModal:false,
            imgWin:false,
            imgArr:[],
            active:'1', 
            goPage:'',
            curind:-1,
            current:1,
            paymth:'ali',
            paynum:0 ,//待付款
            shpno:0 ,//待发货
            shpyes:0 ,//已发货
            ordList:[], //订单明细
            ordstfg:'all', //订单日期
            v_userno :this.$cookies.get('v_telno' ), //用户名称 
            v_password:this.$cookies.get('v_enpwd') ,//api 请求密码
            condition:{
                idno:'', //询价单 号
                matno:'', //商品编码
                telno:'', //电话号码
                stfg:'all', //订单状态
                dttm:'', //下单日期
                todttm:'', //结束下单日期
                facno:'', //厂牌
            },
            options: [{
                value: 10,
                label: '10'
                }, {
                value: 20,
                label: '20'
                }, {
                value: 50,
                label: '50'
            }],
            fldObj:{},
            dataCount:0,
            rowSize:10,
            ordArr:[],
            listurl:'',
            showViewer:false,
            //日期选项
            pickerOptions: {
                // disabledDate(time) {
                //     return time.getTime() > Date.now();
                // },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', new Date());
                    }
                }, 
                {
                    text: '昨天',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', date);
                    }
                }, 
                {
                    text: '一周前',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    }
                }]
            },
        }
    },
    components: {
      //askPrc,  
    },
    computed: {

    },
    created () {
        if (this.v_userno){
            this.getOrdList(' order by adddttm desc')
            this.getField()
        }else{
            let routeUrl = this.$router.resolve({
                path: "/frontLogin",
                query: {lang:this.$store.state.lang,form:'base' },
            });
            window.open(routeUrl.href, '_self');
        }
    },
    methods: {
        //界面字段语言设置
        getField(){
            let that =this
            this.$axios({   
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getBaseData',
                data: {p_key:'idseq', p_value:'55904',p_table:'V_BS_PAGEDTL'},
            }).then(res=>{ 
                for(let k=0 ;k<res.data.result.length; k++){
                    that.fldObj[res.data.result[k]['SUBNO']] =that.v_lang=='CN'?res.data.result[k]['CNAME']:res.data.result[k]['ENAME']
                }
                that.$forceUpdate()
            })        
        },
        //图片预览
        imgPreview(path){
            this.showViewer=true
            this.listurl=path
        },
        //预览PDF
        pdfViewer(filePath){
            let routeUrl = this.$router.resolve({
                path: '/fullPreview',
                query: {lang:this.LANG,fileUrl:filePath},
            });
            window.open(routeUrl.href, '_blank');
        },
        imgPdf(idseq,lstseq){
            this.imgArr=[]
            this.$axios({  //传入 p_frmid 参数是为了生成 表单 的编码
                method: 'post',
                url:this.$store.state.baseUrl+'sysprivs/getTableRecMany?username='+this.v_userno+'&password='+this.v_password,
                data: {p_key:'idseq',p_value:idseq,p_key2:'lstseq',p_value2:lstseq, p_table:'PRC_REQFILE' },
            }).then(res=>{ 
                this.imgArr =res.data
            })
            this.imgWin =true
        } ,
        //产品详情页
        prdList(idseq,idno){
            let routeUrl = this.$router.resolve({
                path: '/prdList',
                query: { facid:idno,idseq:idseq,lang:this.v_lang},
            })
            window.open(routeUrl.href, '_blank');
        },
        //询价事件
        addReqNo(){
            let routeUrl = this.$router.resolve({
                path: '/prd_AskPrc',
                query: {lang:this.v_lang},
            });
            window.open(routeUrl.href, '_blank');
        },
        // 产品详情
        itemClick(val){
            console.log('idseq:'+val)
        },
 
        btnClear() {
            this.condition={stfg:'all'}
        },
        //菜单事件
        btnQry(){ 
            let v_cond=''
            if (this.condition.idno){ //订单编码
                v_cond =v_cond +' and idno like \'%'+this.condition.idno+'%\''
            }
            if (this.condition.stfg!=='all'){ //状态
                v_cond =v_cond +' and stfg = \''+this.condition.stfg+'\''
            }
            if (this.condition.dttm){ //下单日期
                v_cond =v_cond +' and adddttm>=\''+this.condition.dttm+'\''
            }
            if (this.condition.todttm){ //下单日期
                v_cond =v_cond +' and adddttm<=\''+this.condition.todttm+'\''
            }
            if (this.condition.matno){ //产品型号
                v_cond =v_cond +' and idseq in (select idseq from prc_reqdtl where matno like \'%'+this.condition.matno+'%\')' 
            }
            if (this.condition.facno){ //厂牌
                v_cond =v_cond +' and idseq in (select idseq from prc_reqdtl where facno like \'%'+this.condition.facno+'%\')' 
            }
 
            this.getOrdList(v_cond)
 
        },
        goNext(){
            if (this.goPage){
                this.changePage(this.goPage)
                this.current =Number(this.goPage)
            }else{
                this.$alert('请输入跳至页数', '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    type:'info'
                })
            }
        },
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.rowSize;
            var _end = index * this.rowSize;
            this.ordArr = this.ordList.slice(_start,_end);
        },
        //初始化查询页条数
        initPage(){
            //产品明细
            this.dataCount =this.ordList.length //总条数
            // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
            if (this.ordList.length < this.rowSize){
                this.ordArr = this.ordList          
            }else{
                this.ordArr =this.ordList.slice(0,this.rowSize) //返回选定元素的子数组，不包含结尾元素
            }
        },
 
        //订单明细
        getOrdList(cond){
            this.$axios({
                method:'post',
                url: this.$store.state.baseUrl+'auth/login/getMainSub' ,
                data:{ p_key:'empno',p_value:this.v_userno,maintab:'v_prc_req',subtab:'V_prc_reqdtl',p_cond:cond },
            }).then(res =>{
                if (res.data.code==='200'){
                    this.ordList =res.data.result
                    this.initPage() 
                }else{
                    this.$alert(res.data.result, '提示', {
                        confirmButtonText: '确定',
                        showClose:false,
                        type:'warning'
                    });
                }
            })
        }
    }
}
</script>
<style lang="less">
 
    .el-dialog__body{
        padding: 5px 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn { margin-top:0px !important;  }
    .el-dialog__headerbtn i { background:white;font-size: 20px; }
</style>

 
<style scoped lang="less">
    .divhover{
        border:1px solid transparent;
    }
    .divhover:hover{
        border: 1px solid rgb(120, 162, 240);
    }
    .top1{
        margin-top:5px;
    }
    .top2{
        margin-top:20px;
    }
    .btncss{
        
        margin-left:85px; 
        font-size:14px;
    }
    .btncss:hover{
        background:#ed4014;
    }
    .btncss2{
        background:#d9e7db;
        font-size:14px;
    }
    .btncss2:hover{
        background:#30cfe4;
        border:1px solid green;
        color:white;
    }
    .head{
        color: #606266;
        font-size:13px;
        font-weight:600;
    }
    .notop{
        // border-right :1px solid #ccc;
        // border-left :1px solid #ccc;
        // border-bottom :1px solid #ccc;
        background: rgb(241, 240, 240);
    }
    .bkcolor{
        background-color: #f8f9fa;
        font-size:13px;
        font-weight:600;
        line-height:35px;
        border-left :1px solid #ccc;
        border-right :1px solid #ccc;
    }
    .title {
        line-height:35px;
        border-right :1px solid #ccc;
        border-left :1px solid #ccc;
        border-bottom :1px solid #ccc;
        display:flex;
        font-size:13px;
        font-weight:600;
    }
    .column{
        border-left:1px solid #ccc;
        border-top:1px solid #ccc;
    }
    .divcol{
        line-height:30px;
        border-top :1px solid #ccc;
        border-right :1px solid #ccc;
        font-size:14px;
        text-align:left;
        padding-left:5px;
    }
    .divcol2{
        line-height:30px;       
        border-top :1px solid #ccc;
        font-size:12px;
        text-align:left;
        padding-left:5px;
    }
    .opercol{
        line-height:30px;
        border-left :1px solid #ccc;
        border-right :1px solid #ccc;
        border-top :1px solid #ccc;
        font-size:14px;
        text-align:center;
    }
    .opercol2{
        line-height:30px;
        border-left :1px solid #ccc;
        border-right :1px solid #ccc;
        font-size:14px;
        text-align:center;
    }
 
</style>